import React from "react";
import Layout from "../components/layout";
// import Calendar from "../components/calendar";
// import MyCard from "../components/card";
import { Container, Row, Col, Image } from "react-bootstrap";

export default function Home() {
  return (
      <Layout>
        <Container fluid>
          <Row className="justify-content-md-center">
            <Col lg="6">
              <div>
                <h1 style={{ paddingTop: `2rem`}}>Ignition Perspective Development</h1>
                <p className="mt-2">
                  Welcome! This site is a repository for useful Perspective resources, including blog posts, documentation, and custom snippets and modules. I hope you find the information here useful!
                </p>
                {/* <Calendar style={{ width: `50px`, height: `50px` }}></Calendar>
                <h2 style={{ paddingBottom: `1rem`, paddingTop: `2rem`, display: `flex`, justifyContent: `center`}}>Why choose The Web Pleb?</h2> */}
              </div>
            </Col>
          </Row>
          <Row className="justify-content-md-center my-5">
            <Col sm={10} lg={6}>
                <Image className="image-margin-bottom" src="https://images.unsplash.com/photo-1563206767-5b18f218e8de?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1949&q=80" rounded />
            </Col>
          </Row>
        </Container>
        {/* <Container fluid className="container">
          <Row>
            <Col xs="12" md="6" lg="4">
              <MyCard cardTitle="" cardBody="" headerText="Performant" buttonText="Check speed!" style={{ height: `450px`}}>We build websites that are fast. Whether you need a photography blog, a resume site, or an e-commerce store, we will optimize and configure your site to give you the lowest lag possible.</MyCard>
            </Col>
            <Col xs="12" md="6" lg="4">
              <MyCard cardTitle="" cardBody="" headerText="Unique" buttonText="Double-click me!" style={{ height: `450px`}}>Our websites are always custom-built. We don't use templates to create generic sites, but instead work with you to get your new website looking exactly how you want it to.</MyCard>
            </Col>
            <Col xs="12" md="6" lg="4">
              <MyCard cardTitle="" cardBody="" headerText="Responsive" buttonText="Press F12" style={{ height: `450px`}}>We take pride in creating <a href="https://www.cnbc.com/2019/01/24/smartphones-72percent-of-people-will-use-only-mobile-for-internet-by-2025.html">responsive</a> websites! We use modern frameworks to make all of our site components dynamically respond to device height and width constraints.</MyCard>
            </Col>
          </Row>
        </Container> */}
      </Layout>
      /*<Layout>
      
      
        old style - not responsive!!!!
      <div style={{ width: `1000px`, margin: `auto` }} className="row">
        <MyCard cardTitle="" cardBody="" buttonText="Check speed!"><p>We build websites that are <strong><i>FAST!</i></strong> Whether you need a photography blog, a resume site, or an e-commerce store, we will optimize and configure your site to give you the lowest lag possible. </p></MyCard>
        <MyCard cardTitle="" cardBody="" buttonText="Double-click me!"><p>Our websites are always <strong>CUSTOM-BUILT!</strong> We don't use templates to create generic sites, but instead work with you to get your new website looking exactly how you want it to.</p></MyCard>
        <MyCard cardTitle="" cardBody="" buttonText="Press F12"><p>We take pride in creating <strong>RESPONSIVE</strong> websites! According to <a href="https://www.cnbc.com/2019/01/24/smartphones-72percent-of-people-will-use-only-mobile-for-internet-by-2025.html">CNBC</a>, 51% of Internet users access the web exclusively through a mobile device. We use modern frameworks to make all of our site components dynamically respond to device height and width constraints.</p></MyCard>
      </div> */
    // </Layout>
  );
}